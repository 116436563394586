import React from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryFallback from '../components/common/AppErrorBoundary/ErrorBoundaryFallback'

const withAppErrorBoundary = (Component: React.FC) => withErrorBoundary(Component, {
    FallbackComponent: ErrorBoundaryFallback,
    onError(error, info) {
        // Do something with the error
        // E.g. log to an error logging client here
        console.log(error, info)
    },
})

export default withAppErrorBoundary