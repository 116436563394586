import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { logoutDispatcher, setPartnerDetailsDispatcher, setPermissionsByKeysDispatcher, setPermissionsDispatcher, setUserDetailsDispatcher } from '../../../redux/dispatchers/authDispatchers'
import { getLoggedInUserDetails } from '../../../services/users'
import { objectifyArrayOfObjects } from '../../../utils/others'
import CircularLoader from '../Loaders/CircularLoader'
import Text from '../Text'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem 0'
    },
}))

const LoginSessionWrapper: FC<{}> = ({
    children
}) => {
    const classes = useStyles()
    const [renderUi, setRenderUi] = useState(false)
    const dispatch = useDispatch()

    const fetchUserDetails = () => {
        getLoggedInUserDetails().then(response => {
            if (response.data) {
                dispatch(setUserDetailsDispatcher(response.data.payload.userEntity))
                dispatch(setPermissionsDispatcher([
                    ...response.data.payload.userEntity.roleEntity.permissionEntities.map(permission => permission.key)
                ]))
                dispatch(setPermissionsByKeysDispatcher(objectifyArrayOfObjects<Permission>(response.data.payload.userEntity.roleEntity.permissionEntities, 'key')))

                if (response.data.payload.partnerEntity) {
                    dispatch(setPartnerDetailsDispatcher(response.data.payload.partnerEntity))
                }
            }
            else if (response.error) {
                if (response.error.response?.status === 401) {
                    dispatch(logoutDispatcher())
                    return
                }
            }

            setRenderUi(true)
        })
    }

    useEffect(() => {
        fetchUserDetails()
    }, [])

    if (!renderUi) {
        return (
            <Box className={classes.root}>
                <CircularLoader />
                <Text fontSize={24} fontWeight={700} mt={3}>Loading...</Text>
                <Text fontSize={14}>Please wait while we check your login session.</Text>
            </Box>
        )
    }

    return (
        <>{children}</>
    )
}

export default LoginSessionWrapper