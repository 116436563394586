import { useEffect } from 'react';
import {
	QueryClient,
	QueryClientProvider
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';
import { PersistGate } from 'redux-persist/integration/react';
import AppTheme from './components/common/Theme/AppTheme';
import MasterdataWrapper from './components/common/Wrappers/MasterdataWrapper';
import AppRouter from './components/router/AppRouter';
import startFirebaseAnalytics from './firebase/analytics';
import messaging from './firebase/messaging';
import { persistor, store } from './redux';


function App() {
	const queryClient = new QueryClient()

	useEffect(() => {
		startFirebaseAnalytics()
		messaging()
	}, [])


	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<QueryClientProvider client={queryClient}>
					<MasterdataWrapper>
						<AppTheme>
							<AppRouter />
						</AppTheme>
					</MasterdataWrapper>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
