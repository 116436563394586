import { ErrorMessage } from '@hookform/error-message';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/material';
import React from 'react';
import Text from '../Text';

interface Props {
    errors: any,
    name: string
}

const InputErrors: React.FC<Props> = ({ errors, name }) => {
    return (

        errors ?
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                    <Box display='flex' alignItems='center' mt={0.5}>
                        <InfoIcon color='error' sx={{ marginRight: 0.5, width: 14, height: 14 }} />
                        <Text fontSize={14} variant='body1' color='error'>{message}</Text>
                    </Box>
                )}
            /> : null

    )
}

export default InputErrors
