import { masterdataConstants } from "../constants/masterdata"

export const publicMasterdataAction = (payload: any) => {
    return {
        type: masterdataConstants.FETCH_PUBLIC_MASTER_DATA,
        payload
    }
}

export const privateMasterdataAction = (payload: any) => {
    return {
        type: masterdataConstants.FETCH_PRIVATE_MASTER_DATA,
        payload
    }
}

export const clearPrivateMasterdataAction = () => {
    return {
        type: masterdataConstants.CLEAR_PRIVATE_MASTER_DATA,
    }
}