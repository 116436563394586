import moment, { Moment } from "moment"

export const formatTimestampForTable = (timestamp: moment.MomentInput) => {
    return !!timestamp ? moment(timestamp).format('DD MMMM YYYY, hh:mm A') : '---'
}

export const formatDateTime = (timestamp: moment.MomentInput, format = 'YYYY-MM-DDThh:mm', currentFormat = 'YYYY-MM-DDThh:mm') => {
    return moment(timestamp, currentFormat).format(format)
}

export const formatDateTimeWithZeroOffset = (timestamp: moment.MomentInput, hasTime = false, dayTime: 'startOf' | 'endOf' = 'startOf') => {
    if (hasTime) {
        return moment(moment(timestamp).toISOString(true)).format('YYYY-MM-DDTHH:mm:ss').concat(`.${dayTime === 'startOf' ? "000" : "999"}Z`)
    }
    return moment(moment(timestamp)[dayTime]('day').toISOString(true)).format('YYYY-MM-DDTHH:mm:ss').concat(`.${dayTime === 'startOf' ? "000" : "999"}Z`)
}

export const getBackendFormattedStartDate = (date: Moment) => date.format('YYYY-MM-DD').concat('T00:00:00.000Z')
export const getBackendFormattedEndDate = (date: Moment) => date.format('YYYY-MM-DD').concat('T23:59:59.999Z')

export const formatDateRangeForBackend = (data: DateRange) => ({
    fromDate: getBackendFormattedStartDate(moment(data.fromDate)),
    toDate: getBackendFormattedEndDate(moment(data.toDate))
})

export const getDefaultDateRange = () => ({
    fromDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
})