import reducers from "../redux/reducers";

export enum PlanTypes {
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    Annual = 'Annual',
}

export enum PartnerFirmTypes {
    Partnership = 'PARTNERSHIP',
    Proprietorship = 'PROPRIETORSHIP',
    'Pvt Ltd.' = 'PVT_LTD'
}

export enum RetailerShopTypes {
    'Consumer goods' = 'Consumer goods',
    'Consumer electronics' = 'Consumer electronics',
    'Recharge outlets' = 'Recharge outlets',
    'Modern Trade Store' = 'Modern Trade Store',
    'LCO-ISP' = 'LCO-ISP',
    'kirana Shop' = 'kirana Shop',
    'Handset Outlet' = 'Handset Outlet',
    Others = 'Others'
}

export enum PartnerCurrentBusinessType {
    'Cable Distribution' = 'CABLE_DISTRIBUTION',
    'CE & Household' = 'CE_AND_HOUSEHOLD',
    'DTH' = 'DTH',
    'ISP' = 'ISP',
    'MSO' = 'MSO',
    'Telecom' = 'TELECOM',
    'Others' = 'OTHERS',
}

export enum RetailerRoleTypes {
    DEALER = 'DEALER',
    LCO = 'LCO',
    RESELLER = 'RESELLER'
}

export enum PartnerOrderType {
    CREDIT = 'CREDIT',
    INCENTIVE = 'INCENTIVE',
    COMMISSION = 'COMMISSION'
}

export enum TransactionCategory {
    COMMISSION_TRANSFER = 'COMMISSION_TRANSFER',
    CREDIT_TRANSFER = 'CREDIT_TRANSFER',
    INCENTIVE_TRANSFER = 'INCENTIVE_TRANSFER',
    RECHARGE_CUSTOMER = 'RECHARGE_CUSTOMER',
    RECHARGE_RETAILER = 'RECHARGE_RETAILER',
    RETAILER_RECHARGE_REVERSAL = 'RETAILER_RECHARGE_REVERSAL'
}

export enum TransactionType {
    CREDIT = 'CREDIT',
    DEBIT = 'DEBIT'
}

export enum TemplateKeys {
    BALANCE_TRANSFER_AGAINST_PO = 'BALANCE_TRANSFER_AGAINST_PO',
    CHANGE_EMAIL = 'CHANGE_EMAIL',
    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
    COMMISSION_TRANSFER_PARTNER = 'COMMISSION_TRANSFER_PARTNER',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    INCENTIVE_TRANSFER_PARTNER = 'INCENTIVE_TRANSFER_PARTNER',
    PARTNER_GO_LIVE = 'PARTNER_GO_LIVE',
    PO_RAISE = 'PO_RAISE',
    PO_RAISE_FIN_TEAM = 'PO_RAISE_FIN_TEAM',
    RETAILER_GO_LIVE = 'RETAILER_GO_LIVE',
    SUBSCRIPTION_PURCHASE_CUSTOMER = 'SUBSCRIPTION_PURCHASE_CUSTOMER',
    SUBSCRIPTION_PURCHASE_RETAILER = 'SUBSCRIPTION_PURCHASE_RETAILER',
    VERIFY_EMAIL = 'VERIFY_EMAIL',
    VERIFY_MOBILE = 'VERIFY_MOBILE',
    VIRTUAL_BAL_TRANSFER_PARTNER_TO_RETAILER = 'VIRTUAL_BAL_TRANSFER_PARTNER_TO_RETAILER',
    RETAILER_RECHARGE_REVERSAL = 'RETAILER_RECHARGE_REVERSAL',
    RETAILER_CREDIT_FROM_ADMIN = 'RETAILER_CREDIT_FROM_ADMIN'
}

declare global {
    interface ISelectOptionItem<TValue> {
        label: string,
        value: TValue
    }
    type IAppTableColumnOptions = {
        customBodyRender?: (value: T, index: number) => React.ReactNode | undefined;
        format?: 'text' | 'number' | 'date'
    } | {
        customBodyRender?: (value: T, index: number) => React.ReactNode | undefined;
        format?: 'money'
        currencyColumnName?: string
    }

    interface PermissionsConstant {
        forPages: {
            [key: string]: string
        }
        forSidebar: {
            [key: string]: string
        }
    }

    type CustomTableColumnName = 'status' | 'options' | 'goLive'

    interface IAppTableColumn<T> {
        name: keyof T | CustomTableColumnName
        label: string
        headCellProps?: TableCellProps
        options?: IAppTableColumnOptions
    }

    interface AppTableDataStatus {
        tableDataStatus: number
    }

    interface RequestError {
        message: string
        statusCode: number
    }

    interface Response<T> {
        message: string
        statusCode: string
        payload: T
    }
    interface Permission {
        createdAt: string
        createdBy: string
        groupId: number
        id: string
        isActive: number
        isDeleted: number
        key: string
        lastModifiedBy: string
        lastModifiedDate: string
        name: string
    }

    interface Role {
        adminPortal: boolean
        createdAt: string
        createdBy: string
        description: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedBy: string
        lastModifiedDate: string
        name: string
        permissionEntities: Permission[]
        group: number
    }

    interface User {
        createdAt: string
        createdBy: string
        name: string
        email: string
        emailVerified: number
        id: string
        isActive: number
        isDeleted: number
        lastModifiedBy: string
        lastModifiedDate: string
        roleEntity: Role
        mobile: string
        mobileVerified: number
    }

    interface Otp {
        email: string
        expiresAt: string
        id: string
        mobile: string
        tryTimes: number
    }
    interface LoginResponse extends Response<{
        jwt: string
        expiration: string
        refreshToken: string
        userEntity: User
        verified: boolean
        otpResDto: Otp
    }> { }

    interface IPublicForms { }

    interface CreatePermissionReqBody {
        key: string
        name: string
    }

    interface CreateAdminReqBody<TRole> {
        name: string
        email: string
        password: string
        mobile: string
        role: TRole
    }

    interface Status {
        isActive: number
        isDeleted: number
    }

    type RootState = ReturnType<typeof reducers>

    interface CreateRoleReqBody {
        group: number
        adminPortal: boolean
        description: string
        name: string
        permissionId: string[]
    }

    interface PartnerBankAccount {
        acHolderName: string
        acNo: string
        bankName: string
        branchName: string
        createdAt: string
        createdBy: string
        id: string
        ifscCode: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
    }

    interface PartnerCurrentBusiness {
        createdAt: string
        createdBy: string
        currentBusinessAssociation: string
        currentBusinessTurnover: number
        currentBusinessType: PartnerCurrentBusinessType
        currentManpower: number
        existingRelationship: true
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
    }

    interface AadharCard {
        createdAt: string
        createdBy: string
        downloadUrl: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        name: string
        path: string
        type: string
        uploadUrl: string
    }

    interface BankStatement {
        createdAt: string
        createdBy: string
        downloadUrl: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        name: string
        path: string
        type: string
        uploadUrl: string
    }

    interface BlankCheque {
        createdAt: string
        createdBy: string
        downloadUrl: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        name: string
        path: string
        type: string
        uploadUrl: string
    }

    interface GstCertificate {
        createdAt: string
        createdBy: string
        downloadUrl: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        name: string
        path: string
        type: string
        uploadUrl: string
    }

    interface LegalAgreement {
        createdAt: string
        createdBy: string
        downloadUrl: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        name: string
        path: string
        type: string
        uploadUrl: string
    }

    interface MsmeCertificate {
        createdAt: string
        createdBy: string
        downloadUrl: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        name: string
        path: string
        type: string
        uploadUrl: string
    }

    interface PanCard {
        createdAt: string
        createdBy: string
        downloadUrl: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        name: string
        path: string
        type: string
        uploadUrl: string
    }

    interface PassportPhoto {
        createdAt: string
        createdBy: string
        downloadUrl: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        name: string
        path: string
        type: string
        uploadUrl: string
    }

    interface TradeLicense {
        createdAt: string
        createdBy: string
        downloadUrl: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        name: string
        path: string
        type: string
        uploadUrl: string
    }

    interface Doc {
        createdAt: string
        createdBy: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        aadharCard: AadharCard
        bankStatement: BankStatement
        blankCheque: BlankCheque,
        gstCertificate: GstCertificate,
        legalAgreement: LegalAgreement
        msmeCertificate: MsmeCertificate
        panCard: PanCard
        passportPhoto: PassportPhoto
        tradeLicense: TradeLicense
    }

    interface WalletEntity {
        balance: number
        commission: number
        createdAt: string
        createdBy: string
        credit: number
        id: string
        incentive: number
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
    }


    interface Partner {
        aadharNumber: string
        address: string
        district: string
        bankAcEntity: PartnerBankAccount
        city: string
        country: string
        createdAt: string
        createdBy: string
        currentBizEntity: PartnerCurrentBusiness
        docEntity: Doc
        email: string
        firmName: string
        firmType: PartnerFirmTypes
        gstin: true
        gstinNumber: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        mobile: string
        msmeRegistration: true
        msmeRegistrationNumber: string
        panNumber: string
        pinCode: string
        state: string
        userEntity: User
        walletEntity: WalletEntity
        yearOfEstablishment: string
        mobileVerified: 1 | 0
        emailVerified: 1 | 0
    }

    interface CreatePartnerReqBody {
        aadharNumber: string
        address: string
        city: string
        district: string
        country: string
        email: string
        firmName: string
        firmType: PartnerFirmTypes
        gstin: boolean
        gstinNumber: string
        mobile: string
        msmeRegistration: boolean
        msmeRegistrationNumber: string
        panNumber: string
        pinCode: string
        state: string
        yearOfEstablishment: string
    }

    interface GoLiveReqBody {
        password?: string
        roleId: string
    }

    interface PartnerBankAccountReqBody {
        acHolderName: string
        acNo: string
        bankName: string
        branchName: string
        ifscCode: string
    }

    interface PartnerCurrentBusinessReqBody {
        currentBusinessAssociation: string
        currentBusinessTurnover: number
        currentBusinessType: PartnerCurrentBusinessType
        currentManpower: number
        existingRelationship: boolean
    }

    interface PartnerDocForm {
        aadharCard: File
        bankStatement: File
        blankCheque: File
        gstCertificate: File
        legalAgreement: File
        msmeCertificate: File
        panCard: File
        passportPhoto: File
        tradeLicense: File
    }

    interface PartnerDocReqBody {
        aadharCard: string
        bankStatement: string
        blankCheque: string
        gstCertificate: string
        legalAgreement: string
        msmeCertificate: string
        panCard: string
        passportPhoto: string
        tradeLicense: string
    }

    interface DocumentManager {
        createdAt: string
        createdBy: string
        downloadUrl: string
        id: string
        isActive: integer
        isDeleted: integer
        lastModifiedAt: string
        lastModifiedBy: string
        name: string
        path: string
        type: string
        uploadUrl: string
    }

    interface DocumentReqBody {
        name: string
        type: string
    }

    interface Retailer {
        address1: string
        createdAt: string
        createdBy: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        mobile: string
        name: string
        partnerEntity: Partner
        planEntities: Plan[]
        walletEntity: WalletEntity | null
        role: RetailerRoleTypes
        shopName: string
        shopType: string
        userEntity: User
        address2: string
        city: string
        country: string
        district: string
        state: string
        pinCode: string
        mobileVerified: 1 | 0
        emailVerified: 1 | 0
    }

    interface RetailerReqBody {
        address1: string
        mobile: string
        name: string
        role: RetailerRoleTypes
        shopName: string
        shopType: string
        address2: string
        city: string
        district: string
        country: string
        district: string
        state: string
        pinCode: string
    }

    interface VerifyOtpForm {
        otp: string
    }

    interface MapRetailerWithPartnerReqBody {
        partnerId: string
        retailerId: string
    }

    interface PartnerOrderReqBody {
        bankTransactionId: string
        orderAmount: string
        transactionDate: string
        partnerOrderType: string
        monthYear: string
    }

    interface PartnerOrder {
        bankTransactionId: string
        comments: string
        createdAt: string
        createdBy: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        orderAmount: string
        partnerEntity: Partner
        transactionDate: string
        transactionId: string
        partnerOrderType: PartnerOrderType
        monthYear: string
    }

    interface CancelPartnerOrderReqBody {
        comments: string
        id: string
    }

    interface UpdatePersonalAccountReqBody {
        email: string
        mobile: string
        password: string
        confirmPassword?: string
    }

    interface RetailerRechargeReqBody {
        amount: number
        retailerId: string
    }

    interface RetailerBalanceReversalReq {
        amount: number
        comments: string
        retailerId: string
        transactionType: TransactionType.CREDIT | TransactionType.DEBIT
    }

    interface RetailerAssignedPlansReq {
        planIds: string[]
    }

    interface Plan {
        createdAt: String
        createdBy: string
        duration: number
        id: string
        isActive: number
        isDeleted: number
        lastModifiedBy: string
        lastModifiedDate: String
        name: string
        partnerCommission: number
        planAmount: string
        planIdentifier: string
        retailerCommission: number
        type: string
        isDefault: number
    }

    interface PlanReqBody {
        duration: number
        name: string
        partnerCommission: number
        planAmount: string
        planIdentifier: string
        retailerCommission: number
        type: string
        isDefault: number
    }

    interface ForgetPasswordForm {
        password: string
        email?: string
        mobile?: string
        confirmPassword?: string
    }

    interface VerifyMobileAndEmailForm {
        mobileOtp: string
        emailOtp: string
    }

    interface LoggedInUser {
        userEntity: User
        partner: boolean
        partnerEntity: Partner | null
        retailer: boolean
        retailerEntity: Retailer | null
    }

    interface PartnerOrderCreditTransferReqBody {
        amount: string
        orderId
    }

    interface CalculateCommissionReqBody<T = string> {
        monthYear: T
        partnerId?: string
    }

    interface PlanWiseCommission {
        plan_id: string
        partner_commission: number
        planName: string
    }
    interface CalculateCommission {
        commission: number,
        planWiseCommission: PlanWiseCommission[]
    }

    interface CalculateIncentiveReqBody {
        monthYear: string
        partnerId?: string
    }
    interface CalculateIncentive {
        billingCount: number
        billingValue: number
        incentive: number
    }

    interface DateRange {
        toDate: string
        fromDate: string
    }

    interface RetailerTransactionsHistory extends DateRange {
        retailerId: string
    }

    interface PartnerTransactionsHistory extends DateRange {
        partnerId?: string
    }

    interface PlanWiseSubscriptionReportReqDto extends DateRange {
        // planId: string
    }

    interface IncentiveCommissionTransferReportReqDto extends DateRange {
        orderType: PartnerOrderType.INCENTIVE | PartnerOrderType.COMMISSION
    }

    interface CustomerResponseDto {
        email: string
        fname: string
        id: string
        lname: string
        mobile: string
        planEntity: Plan
        viewliftRes: string
        createdAt: string
        partnerCommission: number
        retailerCommission: number
    }

    interface RetailerTransactionResponseDto {
        amount: number
        balance: number
        createdAt: string
        createdBy: string
        details: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        lastTransactionId: string
        retailerWalletEntity: WalletEntity
        transactionCategory: TransactionCategory
        transactionType: TransactionType
        retailerEntity: Retailer
    }

    interface PartnerTransactionResponseDto {
        amount: number
        balance: number
        createdAt: string
        createdBy: string
        details: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedAt: string
        lastModifiedBy: string
        lastTransactionId: string
        partnerWalletEntity: WalletEntity
        transactionCategory: TransactionCategory
        transactionType: TransactionType
        partnerEntity: Partner
    }

    interface EmailTemplate {
        createdAt: string
        createdBy: string
        emailTemplateName: string
        fromEmail: string
        fromName: string
        id: string
        isActive: number
        isDeleted: number
        key: TemplateKeys
        lastModifiedBy: string
        lastModifiedDate: string
        staticToEmail: boolean
        subject: string
        toEmail: string
    }

    interface EmailTemplateReqDto {
        emailTemplateName: string
        fromEmail: string
        fromName: string
        key: TemplateKeys
        staticToEmail: boolean
        subject: string
        toEmail: string | null
    }

    interface SmsTemplate {
        createdAt: string
        createdBy: string
        id: string
        isActive: number
        isDeleted: number
        key: TemplateKeys
        lastModifiedBy: string
        lastModifiedDate: string
        smsTemplate: string
        smsTemplateId: string
    }

    interface SmsTemplateReqDto {
        key: TemplateKeys
        smsTemplate: string
        smsTemplateId: string
    }

    interface TotalSubsSoldPlanWiseResDto {
        count: number
        planId: string
        planName: string
        planPrice: string
        totalSale: number
    }

    interface Variable {
        createdAt: string
        createdBy: string
        id: string
        isActive: number
        isDeleted: number
        lastModifiedBy: string
        lastModifiedDate: string
        name: string
        type: string
        value: string
    }

    interface VariableReqDto {
        name: string
        value: string
    }

    interface PrimaryReport {
        partner_name: string
        partner_district: string
        transaction_id: string
        po_amount: number
        amount_transferred: number
        po_id: string
        partner_id: string
        partner_mobile: string
        partner_stock: number
        created_at: string
    }

    interface SecondaryReport {
        retailer_city: string
        retailer_name: string
        partner_name: string
        partner_district: string
        retailer_id: string
        retailer_mobile: string
        partner_id: string
        partner_mobile: string
        retailer_pincode: string
        transaction_amount: number
        transaction_id: string
        created_at: string
    }

    interface TertiaryReport {
        plan_name: string
        retailer_name: string
        retailer_id: string
        pin_code: string
        partner_name: string
        transaction_id: string
        retailer_mobile: string
        plan_value: Number
        partner_id: string
        partner_mobile: string
        city: string
        partner_district: string
        created_at: string
    }

    interface PlanWiseSubscriptionReport {
        pin_code: string
        partner_name: string
        city: string
        retailer_mobile: string
        plan_name: string
        partner_mobile: string
        retailer_id: string
        retailer_name: string
        customer_f_name: string | null,
        customer_l_name: string | null,
        plan_value: number
        partner_id: string
        customer_mobile: string
        partner_district: string
        created_at: string
        partner_commission: number
        retailer_commission: number
    }

    interface IncentiveCommissionTransferReport {
        incentive_percentage: null | number,
        month: null | number,
        partner_name: string
        partner_district: string
        transaction_id: string
        partner_stock: number
        order_type: string
        partner_id: string
        amount: number
        partner_mobile: string
        transaction_amount: number
        created_at: string
    }

    interface Banner {
        createdAt: string
        createdBy: string
        description: string
        id: string
        imageUrlBig: string
        imageUrlMobile: string
        isActive: number
        isDeleted: number
        lastModifiedBy: string
        lastModifiedDate: string
        name: string
        priority: number
    }

    interface BannerReqBody {
        description: string
        imageUrlBig: string
        imageUrlMobile: string
        name: string
        priority: number
    }

    interface PostOffice {
        Name: string
        Description: string | null
        BranchType: string
        DeliveryStatus: string
        Circle: string
        District: string
        Division: string
        Region: string
        Block: string
        State: string
        Country: string
        Pincode: string
    }

    interface PincodeDetailsResponse {
        Message: string
        Status: string
        PostOffice: PostOffice[]
    }
}