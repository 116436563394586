import copy from 'copy-to-clipboard';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { CurrencyType } from '../types';
import { TransactionCategory } from '../types/App.d';

/**
 * @desc Used for assigning unique ids
 * @param size length of generated id (default 21)
 * @returns string
 * @see https://www.npmjs.com/package/nanoid
 * @example 
 * "V1StGXR8_Z5jdHi6B-myT"
 */
export const getUuid = (size = 21) => nanoid(size)


// ?Converts 'user/:user_id/group/:id/role/:roleId' => 'user/1/group/21/role/3'
export const replaceVariableWithValueInURL = (url: string, values:string[] = []) => {   

    if (!url) {
        return "";
    }

    // regex => `:anyVar1able`
    // eslint-disable-next-line no-useless-escape
    const regex = /\:[a-zA-Z0-9\_]*/;

    let newUrl = url.concat("");

    console.log('values', values);
    

    for (let i = 0; i < values.length; i++) {

        const item = values[i];

        const match = regex.exec(newUrl);       

        if (match === null) {
            return newUrl;
        }
        newUrl = newUrl.replace(match[match.length -1], item || '')
    }

    return newUrl;

}

export const preferredCurrencyFormat = (number: CurrencyType, preferredCurrencyCode?: string) => {
    return (!!number || number === 0) ? new Intl.NumberFormat('en-IN', { 
        style: preferredCurrencyCode ? 'currency': undefined, 
        currency: preferredCurrencyCode || undefined, 
        minimumFractionDigits: 0,
    }).format(number) : ``
}

export const formatCurrency = (number: CurrencyType, hideSymbol = false) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0 }).format((!!number || number === 0) ? number  : 0) 
}

export const hasSpecialChars = (str: string) => str.match(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/)

export const handleCopyToClipboard = (data: string, msg: string ='Data has been copied to clipboard' ) => () => {
    copy(data)
    toast.success(msg)
}

export const urlEncode = function (data: any) {
    var str = [];
    for (var p in data) {
        if (
            data.hasOwnProperty(p) &&
            !(data[p] == undefined || data[p] == null)
        ) {
            str.push(
                encodeURIComponent(p) +
                    "=" +
                    (data[p] ? encodeURIComponent(data[p]) : "")
            );
        }
    }
    return str.join("&");
};

export const getValueOrPlaceholder = (value: string | number) => value !== 0 ? (value || '---') : 0

export const objectifyArrayOfObjects = <T>(arr: T[], keyFieldName: string) => {
    let newObj = {}

    // eslint-disable-next-line array-callback-return
    arr.map((obj: T) => {
        // @ts-ignore
        newObj[obj[keyFieldName]] = obj
    })
    return newObj
}

export const makeQuery = (search: string) => {
    return new URLSearchParams(search);
}

export const getFileFromUrl = async(url: string, name: string, defaultType = 'image/*') => {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
        type: data.type || defaultType,
    });
}

export const openInNewTab = (url: string) => {
    window.open(url, '_blank')?.focus();
}

export const showErrorToast = (error: any, fallbackMsg: string) => {
    toast.error((error.response.data.statusCode === 1000) ? error.response.data.message : fallbackMsg)
}

export const getTransactionDescriptionByCategory = (category: string) => {
    switch (category) {
        case TransactionCategory.COMMISSION_TRANSFER:
            return 'Commission has been transferred.'

        case TransactionCategory.CREDIT_TRANSFER:
            return 'Credit has been transferred.'

        case TransactionCategory.INCENTIVE_TRANSFER:
            return 'Credit has been transferred.'

        case TransactionCategory.RECHARGE_CUSTOMER:
            return 'Retailer has recharged a new customer.'

        case TransactionCategory.RECHARGE_RETAILER:
            return "Retailer's account has been recharged."

        case TransactionCategory.RETAILER_RECHARGE_REVERSAL:
            return "Retailer's recharge has been reversed by admin."

        default:
            return 'N.A'
    }
}