import { stringifyUrl } from "query-string";
import Axios from "../interceptors/axiosInstance";
import Request from "../interceptors/Request";

const PermissionService = new Request(Axios)

export const createPermission = (data: CreatePermissionReqBody) => {
    return PermissionService.post<null, Response<Permission>>(stringifyUrl({url: '/permission/create', query: {...data}}), null)
}

export const getActivePermissions = () => {
    return PermissionService.post<null, Response<Permission[]>>('/permission/getActive', null)
}
export const getPermissionById = (data: Status & {permissionId: string}) => {
    return PermissionService.post<null, Response<Permission>>(stringifyUrl({url: '/permission/getById', query: {...data}}), null)
}
export const getPermissionByStatus = (data: Status) => {
    return PermissionService.post<null, Response<Permission[]>>(stringifyUrl({ url: `/permission/getByStatus`, query: {...data} }), null)
}
export const updatePermission = (data: Status & {permissionId: string}) => {
    return PermissionService.post<null, Response<Permission>>(stringifyUrl({url: '/permission/update', query: {...data}}), null)
}
export const updatePermissionState = (data: {isActive: string, permissionId: string}) => {
    return PermissionService.post<null, Response<Permission[]>>(stringifyUrl({url:'/permission/updateState', query: {...data}}), null)
}