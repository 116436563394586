import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CalculateIcon from '@mui/icons-material/Calculate';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import EmailIcon from '@mui/icons-material/Email';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HistoryIcon from '@mui/icons-material/History';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MessageIcon from '@mui/icons-material/Message';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import StoreIcon from '@mui/icons-material/Store';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import permissions from '../../../constants/permissions';
import routes from '../../../constants/routes';
import { getUuid } from '../../../utils/others';

export interface IMenuGenerator {
    key: string
    label: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> | null | undefined;
    permissionKey: string | string[]
    children: IMenuGenerator[]
    pathname: string | null
}

class MenuGenerator implements IMenuGenerator {
    key: string
    label: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> | null | undefined
    permissionKey: string | string[]
    children: IMenuGenerator[]
    pathname: string | null

    constructor(
        label: string,
        pathname: string | null = null,
        icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> | null | undefined = null,
        permissionKey: string | string[],
        children: IMenuGenerator[] = [],
    ) {
        this.key = getUuid();
        this.label = label;
        this.icon = icon;
        this.permissionKey = permissionKey;
        this.children = children;
        this.pathname = pathname;
    }
}

const navMenuList = [
    new MenuGenerator('General', null, null, permissions.forSidebar.General, [
        new MenuGenerator('Dashboard', routes.adminPages.DashboardPage.url(), DashboardIcon, [
            permissions.forPages.DashboardPage,
            permissions.forPages.DashboardPagePartner,
        ]),
        new MenuGenerator('Plans', routes.adminPages.AllPlansPage.url(), ConfirmationNumberIcon, permissions.forPages.AllPlansPage),
        new MenuGenerator('Banners', routes.adminPages.AllBannersPage.url(), ViewCarouselIcon, permissions.forPages.AllBannersPage),
        new MenuGenerator('Variables', routes.adminPages.AllVariablesPage.url(), FormatListBulletedIcon, permissions.forPages.AllVariablesPage),
        new MenuGenerator('Email templates', routes.adminPages.AllEmailTemplatesPage.url(), EmailIcon, permissions.forPages.AllEmailTemplatesPage),
        new MenuGenerator('SMS templates', routes.adminPages.AllSmsTemplatesPage.url(), MessageIcon, permissions.forPages.AllSmsTemplatesPage),
    ]),
    new MenuGenerator('Users', null, null, permissions.forSidebar.Users, [
        new MenuGenerator('Admins', routes.adminPages.AllAdminsPage.url(), AdminPanelSettingsIcon, permissions.forPages.AllAdminsPage),
        new MenuGenerator('Partners', routes.adminPages.AllPartnersPage.url(), ManageAccountsIcon, permissions.forPages.AllPartnersPage),
        new MenuGenerator('Retailers', routes.adminPages.AllRetailersPage.url(), PeopleIcon, [
            permissions.forPages.AllRetailersPage,
            permissions.forPages.AllRetailersByPartner
        ]),
    ]),
    new MenuGenerator('Transactions', null, null, permissions.forSidebar.Transactions, [
        new MenuGenerator('All orders', routes.adminPages.AllPartnerOrdersPage.url(), ListAltIcon, [
            permissions.forPages.AllPartnerOrdersPage,
            permissions.forPages.AllPartnerOrdersByPartner
        ]),
        new MenuGenerator('Calculations', routes.adminPages.AllCalculationsPage.url(), CalculateIcon, [
            permissions.forPages.AllCalculationsPage,
            permissions.forPages.AllCalculationsPageForPartner
        ]),
        new MenuGenerator('Retailer transactions', routes.adminPages.RetailerTransactionHistoryPage.url(), StoreIcon, [
            permissions.forPages.ViewRetailerTransaction,
            permissions.forPages.ViewRetailerTransactionForPartner,
        ]),
        new MenuGenerator('Partner transactions', routes.adminPages.PartnerTransactionHistoryPage.url(), ReceiptLongIcon, [
            permissions.forPages.ViewPartnerTransaction,
            permissions.forPages.ViewPartnerTransactionForPartner,
        ]),
    ]),
    new MenuGenerator('Reports', null, null, permissions.forSidebar.Reports, [
        new MenuGenerator('Primary reports', routes.adminPages.AllPrimaryReportsPage.url(), DescriptionIcon, permissions.forPages.PrimaryReport),
        new MenuGenerator('Secondary reports', routes.adminPages.AllSecondaryReportsPage.url(), DescriptionIcon, permissions.forPages.SecondaryReport),
        new MenuGenerator('Tertiary reports', routes.adminPages.AllTertiaryReportsPage.url(), DescriptionIcon, permissions.forPages.TertiaryReport),
        new MenuGenerator('Plan-wise subscriptions', routes.adminPages.AllPlanWiseSubscriptionReportsPage.url(), DescriptionIcon, permissions.forPages.PlanWiseSubscription),
        new MenuGenerator('Incentive & Commission', routes.adminPages.AllIncentiveCommissionTransferReportsPage.url(), DescriptionIcon, permissions.forPages.AllIncentiveCommissionTransferReportsPage),
    ]),
    new MenuGenerator('History', null, null, permissions.forSidebar.History, [
        new MenuGenerator('Recharge history', routes.adminPages.CustomerRechargeHistoryPage.url(), HistoryIcon, [
            permissions.forPages.ViewAllCustomer,
            permissions.forPages.ViewCustomerByPartner,
        ]),
    ]),
    new MenuGenerator('Authorization center', null, null, permissions.forSidebar.AuthorizationCenter, [
        new MenuGenerator('Roles', routes.adminPages.AllRolesPage.url(), AccountTreeIcon, permissions.forPages.AllRolesPage),
        new MenuGenerator('Permissions', routes.adminPages.AllPermissionsPage.url(), WorkspacesIcon, permissions.forPages.AllPermissionsPage),
    ])
]

export default navMenuList