import nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import React from 'react';

const withRouteProgressBar = (Component: React.FC) => {
    return (props: any) => {
        React.useEffect(() => {
            nprogress.start();

            return () => {
                nprogress.done();
            }
        }, [])

        return (
            <Component {...props} />
        )
    }
}

export default withRouteProgressBar
