import { AxiosInstance, AxiosRequestConfig, CancelToken } from 'axios';
import { toast } from 'react-toastify';
import { store } from '../redux';
import { HttpMethod, IRequestError } from '../types';

const queryString = require('query-string');

interface IOptions {
    checkToken?: boolean
    headers?: object
    cancelToken?: CancelToken | undefined
}

const config: IOptions = {
    checkToken: true,
    headers: {
        'Content-Type': 'application/json'
    },
    cancelToken: undefined
}

class Request {
    private Axios: AxiosInstance
    constructor (AxiosInstance: AxiosInstance) {
        this.Axios = AxiosInstance
    }

    private getResponse = <IRequestBody, IResponseData>(data: IResponseData, error: (IRequestError<IRequestBody> | undefined)) => {
        return {
            data,
            error
        }
    }

    private getErrorResponse = <IRequestBody>(url: string, message: string, method: HttpMethod, response:any, body?: IRequestBody) => {
        return this.getResponse(undefined, {
            url,
            message,
            method,
            body,
            response
        })
    }

    private makeRequestAsPerMethod = async<IRequestBody, IResponseData> (url: string, method: HttpMethod, options: IOptions, body?: IRequestBody, axiosOptions?: AxiosRequestConfig | undefined) => {
        let response = null, errorResponse;
        let authHeader = {};
        let token;

        try {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            if (options.checkToken) {
                const state = store.getState();

                if (state.auth && state.auth.token) {
                    token = state.auth.token;
                    authHeader = {
                        'Authorization': `Bearer ${token}`,
                    };
                }
                else {
                    const message = 'You have enabled token check for this API but token was not found! Make sure you have auth object with token field in your redux store.'
                    console.log("%c Token not found error:", 'background: #000; color: #edff4a', message);
                    return this.getErrorResponse(url, message, method, null, body)
                };
            }
            switch (method.toUpperCase()) {
                case "GET":
                    response = await this.Axios.get(url, {
                        headers: {
                            ...options.headers,
                            ...authHeader,
                        },
                        cancelToken: options.cancelToken
                    });
                    break;
                case "POST":
                    response = await this.Axios.post(url, body, {
                        ...axiosOptions,
                        headers: {
                            ...options.headers,
                            ...authHeader,
                        },
                        cancelToken: options.cancelToken
                    });
                    break;
                case "PUT":
                    response = await this.Axios.put(url, body, {
                        ...axiosOptions,
                        headers: {
                            ...options.headers,
                            ...authHeader,
                        },
                        cancelToken: options.cancelToken
                    });
                    break;
                case "DELETE":
                    response = await this.Axios.delete(url, {
                        headers: {
                            ...options.headers,
                            ...authHeader,
                        },
                        cancelToken: options.cancelToken
                    });
                    break;
                default:
                    break;
            }
        } 
        catch (error: any) {            
            console.log("%c URL ERROR:", 'background: #000; color: #ff4a4a', `An error occured while requesting a url : ${url} \n Error: ${JSON.stringify(error.message)}`);
            if (error.message === "Network Error") {
                toast.error('Please check your internet connectivity!', {
                    position: 'bottom-center',
                    toastId: 'Please check your internet connectivity!'
                })
            }
            errorResponse = error.response
            return this.getErrorResponse<IRequestBody>(url, error.message, method, errorResponse, body)
        }
        finally {
            if (response) {
                return this.getResponse<IRequestBody, IResponseData>(response.data, undefined)
            } else {
                return this.getErrorResponse<IRequestBody>(url, 'Server did not to respond', method, errorResponse, body)
            }
        }
    }

    /**
     * 
     * @param {string} url  The API endpoint
     * @param {object} options { checkToken: true, headers: {} }
     * @returns Promise
     */
    get = async <IResponseData>(url: string, query = {}, options = config) => {
        if (Object.keys(query).length > 0) {
            const newUrl = queryString.stringifyUrl({ url, query });
            return await this.makeRequestAsPerMethod<undefined, IResponseData>(newUrl, 'GET', options)
        }
        return await this.makeRequestAsPerMethod<undefined, IResponseData>(url, 'GET', options)
    }

    /**
     * 
     * @param {string} url  The API endpoint
     * @param {object} body The request body
     * @param {object} options { checkToken: true, headers: {}, axiosOptions: {} }
     * @returns Promise
     */
    post = async <IRequestBody, IResponseData>(url: string, body: IRequestBody, options = { ...config, axiosOptions: {} }) => {
        return this.makeRequestAsPerMethod<typeof body, IResponseData>(url, 'POST', options, body, options.axiosOptions)
    }

    /**
     * 
     * @param {string} url  The API endpoint
     * @param {object} body The request body
     * @param {object} options { checkToken: true, headers: {}, axiosOptions: {} }
     * @returns Promise
     */
    put = async <IRequestBody, IResponseData>(url: string, body: IRequestBody, options = { ...config, axiosOptions: {} }) => {
        return this.makeRequestAsPerMethod<typeof body,IResponseData>(url, 'PUT', options, body, options.axiosOptions)
    }


    /**
     * 
     * @param {string} url  The API endpoint
     * @param {object} options { checkToken: true, headers: {} }
     * @returns Promise
     */
    delete = async <IResponseData>(url: string, options = config) => {
        return await this.makeRequestAsPerMethod<undefined,IResponseData>(url, 'DELETE', options)
    }
}

export default Request