const permissions = {
    forPages: {
        PageNotFound: 'PageNotFound',
        HomePage: 'HomePage',
        LoginPage: 'LoginPage',
        RetailerLoginPage: 'RetailerLoginPage',
        DashboardPage: 'DashboardPage',
        RetailerDashboardPage: 'RetailerDashboardPage',
        DashboardPagePartner: 'DashboardPagePartner',

        AccountPage: 'AccountPage',

        // Admins
        AllAdminsPage: 'AllAdminsPage',
        CreateAdminPage: 'CreateAdminPage',
        EditAdminPage: 'EditAdminPage',
        MapRetailerWithPartner: 'MapRetailerWithPartner',
        MapPlanWithRetailer: 'MapPlanWithRetailer',

        // Partners
        AllPartnersPage: 'AllPartnersPage',
        CreatePartnerPage: 'CreatePartnerPage',
        EditPartnerPage: 'EditPartnerPage',

        // Retailers
        AllRetailersPage: 'AllRetailersPage',
        AllRetailersByPartner: 'AllRetailersByPartner',
        CreateRetailerPage: 'CreateRetailerPage',
        EditRetailerPage: 'EditRetailerPage',
        RetailerDelete: 'RetailerDelete',
        RetailerBalanceReversal: 'RetailerBalanceReversal',
        ActivateRetailer: 'ActivateRetailer',

        // partner orders
        AllPartnerOrdersPage: 'AllPartnerOrdersPage',
        AllPartnerOrdersByPartner: 'AllPartnerOrdersByPartner',
        CreatePartnerOrderPage: 'CreatePartnerOrderPage',
        PartnerTransactionCreditTransfer: 'PartnerTransactionCreditTransfer',
        PartnerTransactionCommissionTransfer: 'PartnerTransactionCommissionTransfer',
        PartnerTransactionIncentiveTransfer: 'PartnerTransactionIncentiveTransfer',
        PartnerTransactionRetailerRecharge: 'PartnerTransactionRetailerRecharge',
        PartnerTransactionCancelOrder: 'PartnerTransactionCancelOrder',
        PartnerTransactionRaiseOrder: 'PartnerTransactionRaiseOrder',
        PartnerTransactionAdminCancelOrder: 'PartnerTransactionAdminCancelOrder',

        // Permissions
        AllPermissionsPage: 'AllPermissionsPage',
        CreatePermissionsPage: 'CreatePermissionsPage',
        EditPermissionsPage: 'EditPermissionsPage',

        // Roles
        AllRolesPage: 'AllRolesPage',
        CreateRolePage: 'CreateRolePage',
        EditRolePage: 'EditRolePage',

        // Plans
        AllPlansPage: 'AllPlansPage',
        CreatePlansPage: 'CreatePlansPage',
        EditPlansPage: 'EditPlansPage',

        // Email templates
        AllEmailTemplatesPage: 'AllEmailTemplatesPage',
        CreateEmailTemplatePage: 'CreateEmailTemplatePage',
        EditEmailTemplatePage: 'EditEmailTemplatePage',

        // Sms templates
        AllSmsTemplatesPage: 'AllSmsTemplatesPage',
        CreateSmsTemplatePage: 'CreateSmsTemplatePage',
        EditSmsTemplatePage: 'EditSmsTemplatePage',

        // Calculations
        AllCalculationsPage: 'AllCalculationsPage',
        AllCalculationsPageForPartner: 'AllCalculationsPageForPartner',
        AdminIncentiveCalculation: 'AdminIncentiveCalculation',
        AdminCommissionCalculation: 'AdminCommissionCalculation',
        PartnerIncentiveCalculation: 'PartnerIncentiveCalculation',
        PartnerCommissionCalculation: 'PartnerCommissionCalculation',

        ViewRetailerTransaction: 'ViewRetailerTransaction',
        ViewRetailerTransactionForPartner: 'ViewRetailerTransactionForPartner',

        ViewPartnerTransaction: 'ViewPartnerTransaction',
        ViewPartnerTransactionForPartner: 'ViewPartnerTransactionForPartner',

        // History
        ViewAllCustomer: 'ViewAllCustomer',
        ViewCustomerByRetailer: 'ViewCustomerByRetailer',
        ViewCustomerByPartner: 'ViewCustomerByPartner',

        // Variables
        AllVariablesPage: 'AllVariablesPage',
        EditVariablesPage: 'EditVariablesPage',

        // Reports
        PrimaryReport: 'PrimaryReport',
        SecondaryReport: 'SecondaryReport',
        TertiaryReport: 'TertiaryReport',
        PlanWiseSubscription: 'PlanWiseSubscription',
        AllIncentiveCommissionTransferReportsPage: 'AllIncentiveCommissionTransferReportsPage',

        // Banners
        AllBannersPage: 'AllBannerPage',
        CreateBannerPage: 'CreateBannerPage',
        EditBannerPage: 'EditBannerPage',
        DeleteBannerPage: 'DeleteBannerPage'
    },

    forSidebar: {
        General: 'General',
        Users: 'Users',
        Transactions: 'Transactions',
        AuthorizationCenter: 'AuthorizationCenter',
        History: 'History',
        Reports: 'Reports'
    }

}

export default permissions;
/**

Permissions to delete
=========================== 
AdminTransactionHistoryPage: 'AdminTransactionHistoryPage',
PartnerTransactionHistoryPage: 'PartnerTransactionHistoryPage'
PartnerBankAccountFormPage: 'PartnerBankAccountFormPage',
PartnerCurrentBusinessPage: 'PartnerCurrentBusinessPage',
PartnerDocumentPage: 'PartnerDocumentPage',
ViewPartnerOrderPage: 'ViewPartnerOrderPage',
AdminCustomerRechargeHistoryPage: 'AdminCustomerRechargeHistoryPage',
PartnerCustomerRechargeHistoryPage: 'PartnerCustomerRechargeHistoryPage',

*/
