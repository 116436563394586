import { LoadableComponent } from "@loadable/component";
import { motion } from "framer-motion";
import React, { Suspense } from "react";
import SuspenseFallback from "../components/common/Loaders/SuspenseFallback";


const withSuspenseWrapper = (Component: LoadableComponent<{}>, pageProps: any) => (props: any) => (
	<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
		<Suspense fallback={<SuspenseFallback />}>
			<Component {...pageProps} {...props} />
		</Suspense>
	</motion.div>
)

export default withSuspenseWrapper
