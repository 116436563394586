import { stringifyUrl } from "query-string";
import { MasterMicroService } from "../interceptors/axiosInstance";
import Request from "../interceptors/Request";

const PlanService = new Request(MasterMicroService)

export const getAllPlansByStatus = (data: Status) => {
    return PlanService.post<null, Response<Plan[]>>(stringifyUrl({url: '/plan/getByStatus', query: {...data}}), null)
}

export const createPlan = (data: PlanReqBody) => {
    return PlanService.post<typeof data, Response<Plan>>('/plan/create', data)
}

export const updatePlan = (data: PlanReqBody, isActive: number, id: string) => {
    return PlanService.post<typeof data, Response<Plan>>(stringifyUrl({url: '/plan/update', query: {isActive, id}}), data)
}

export const getPlanById = (data: Status & {id: string}) => {
    return PlanService.post<{}, Response<Plan>>(stringifyUrl({url: '/plan/getById', query: {...data}}), {})
}

export const updatePlanState = (id: string, isActive: number) => {
    return PlanService.post<null, Response<Plan>>(stringifyUrl({url: '/plan/updateState', query: {isActive, id}}), null)
}

export const getActiveByStatus = () => {
    return PlanService.post<null, Response<Plan[]>>(stringifyUrl({url: '/plan/getActiveByStatus'}), null)
}