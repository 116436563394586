interface IEnv {
    WITH_REDUX_DEVTOOLS: string,
    APP_REDUX_KEY: string,
    ADMIN_BACKEND_BASE_URL: string
    TRANSACTION_BASE_URL: string
    MASTER_BASE_URL: string
    IS_PRODUCTION_MODE: boolean
    PARTNER_ROLE_ID: string
    RETAILER_ROLE_ID: string
    PINCODE_API_BASE_URL: string
}

const env: IEnv = {
    WITH_REDUX_DEVTOOLS: String(process.env.REACT_APP_WITH_REDUX_DEVTOOLS),
    APP_REDUX_KEY: String(process.env.REACT_APP_APP_REDUX_KEY),
    ADMIN_BACKEND_BASE_URL: String(process.env.REACT_APP_BACKEND_BASE_URL),
    TRANSACTION_BASE_URL: String(process.env.REACT_APP_TRANSACTION_BASE_URL),
    MASTER_BASE_URL: String(process.env.REACT_APP_MASTER_BASE_URL),
    IS_PRODUCTION_MODE: String(process.env.REACT_APP_IS_PRODUCTION_MODE) === 'true',
    PARTNER_ROLE_ID: String(process.env.REACT_APP_PARTNER_ROLE_ID),
    RETAILER_ROLE_ID: String(process.env.REACT_APP_RETAILER_ROLE_ID),
    PINCODE_API_BASE_URL: String(process.env.REACT_APP_PINCODE_API_BASE_URL)
}

export default env;