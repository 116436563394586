const USER_AUTHENTICATE = "USER_AUTHENTICATE";
const USER_REGISTER = "USER_REGISTER";
const USER_LOGOUT = "USER_LOGOUT";
const SET_TOKEN_EXPIRY_TIMESTAMP = "SET_TOKEN_EXPIRY_TIMESTAMP";
const TOKEN_REFRESH = "TOKEN_REFRESH"
const SET_USER_DETAILS = "SET_USER_DETAILS"
const SET_PERMISSIONS = "SET_PERMISSIONS"
const SET_PERMISSIONS_BY_KEYS = "SET_PERMISSIONS_BY_KEYS"
const SET_PARTNER_DETAILS = "SET_PARTNER_DETAILS"


const authConstants = {
	USER_AUTHENTICATE,
	USER_REGISTER,
	USER_LOGOUT,
	SET_TOKEN_EXPIRY_TIMESTAMP,
	TOKEN_REFRESH,
	SET_USER_DETAILS,
	SET_PERMISSIONS,
	SET_PERMISSIONS_BY_KEYS,
	SET_PARTNER_DETAILS
};

export default authConstants