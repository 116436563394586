import clsx from 'clsx'
import React from 'react'
import withRouteProgressBar from '../../../hoc/withRouteProgressBar'
import useCommonStyles from '../../../styles/useCommonStyles'
import CircularLoader from './CircularLoader'

const SuspenseFallback = () => {
    const commonClasses = useCommonStyles()

    return (
        <div className={clsx(
            commonClasses.flex,
            commonClasses.alignCenter,
            commonClasses.justifyCenter,
            commonClasses.width100,
            commonClasses.vh50
        )}>
            <CircularLoader />
        </div>
    )
}

export default withRouteProgressBar(SuspenseFallback)
