import axios from "axios";
import { stringifyUrl } from "query-string";
import Axios, { PincodeService } from "../interceptors/axiosInstance";
import Request from "../interceptors/Request";
import { DataIdType, IDateRange, IGeneralResponseData, ILoginFormData } from "../types";
import { formatDateTimeWithZeroOffset } from "../utils/datetime";

const UserService = new Request(Axios)
const AuthService = new Request(Axios)
const PincodeDetailsService = new Request(PincodeService)

export const getAllUsers = () => {
    const { token, cancel } = axios.CancelToken.source()
    return {
        api: () => UserService.get<{ name: string, email: string }[]>('/auth/login', {}, {
            cancelToken: token
        }),
        cancel
    }
}


export const getAllUsersWithoutCancelToken = () => {
    return UserService.get<{ name: string, email: string }[]>('/users')
}

export const login = (data: ILoginFormData, loginWithMobile: boolean) => {
    return AuthService.post<typeof data, LoginResponse>(`/auth/${loginWithMobile ? 'loginMobile' : 'loginEmail'}`, data, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const deletePostById = (id: DataIdType) => {
    return UserService.delete<{}[]>(`/posts/${id}`)
}


export const getAllDemoUsersByDateRange = (data: (IDateRange & { status: number })) => {
    data.fromDate = formatDateTimeWithZeroOffset(data.fromDate, false, 'startOf')
    data.toDate = formatDateTimeWithZeroOffset(data.toDate, false, 'endOf')
    return UserService.post<typeof data, IGeneralResponseData>(stringifyUrl({ url: '/user/getAllUserDetailsByDateRange', query: { ...data } }), data)
}


export const updateDemoUserStatus = (data: { id: string, status: number }) => {
    return UserService.post<typeof data, IGeneralResponseData>(stringifyUrl({ url: '/user/updateUserDetailsStatus', query: { ...data } }), data)
}

export const getLoggedInUserDetails = () => {
    return UserService.post<null, Response<LoggedInUser>>('/auth/getLoggedInUser', null)
}

export const getPincodeDetails = (pincode: number | string) => {
    return PincodeDetailsService.get<PincodeDetailsResponse[]>(`/pincode/${pincode}`, {}, {
        checkToken: false
    })
}