import { CircularProgress, CircularProgressProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';

const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        marginRight: theme.spacing(1),
        "& .MuiCircularProgress-svg": {
            color: theme.palette.primary.main,
        }
    },

}));

interface Props extends CircularProgressProps { }

const CircularLoader: FC<Props> = ({ ...props }) => {
    const classes = useStyles();

    return (
        <CircularProgress className={classes.root} {...props} />
    );
}

export default CircularLoader