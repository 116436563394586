import { Box } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import { icons } from '../../../constants/assets'
import Text from '../Text'

const ErrorBoundaryFallback = () => {
    const theme = useTheme<Theme>()
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <icons.DizzinessFilled color={theme.palette.text.primary} width={120} height={120} />
            <Text fontSize={24} fontWeight={600}>Oops! some error has occured in this part.</Text>
            <Text>You can reload this page to get rid of this message.</Text>
        </Box>
    )
}

export default ErrorBoundaryFallback
