import { lazy, LoadableComponent } from '@loadable/component';
import env from '../env';
import { IGeneratePathForRoutes } from "../types";
import { getUuid, replaceVariableWithValueInURL } from "../utils/others";
import permissions from './permissions';

// public pages
const HomePage = lazy(() => import("../pages/public/HomePage"));
const LoginPage = lazy(() => import("../pages/public/LoginPage"));
const ForgetPasswordPage = lazy(() => import("../pages/public/ForgetPasswordPage"));
const RetailerLoginPage = lazy(() => import("../pages/public/RetailerLoginPage"));
const PageNotFound = lazy(() => import('../pages/PageNotFound'));

// admin/partner pages
const AccountPage = lazy(() => import('../pages/private/AccountPage'))
const DashboardPage = lazy(() => import('../pages/private/DashboardPage'))
const AllAdminsPage = lazy(() => import('../pages/private/Admin/AllAdminsPage'))
const CreateAdminPage = lazy(() => import('../pages/private/Admin/CreateAdminPage'))
const EditAdminsPage = lazy(() => import('../pages/private/Admin/EditAdminsPage'))
const AllPermissionsPage = lazy(() => import('../pages/private/Permissions/AllPermissionsPage'))
const CreatePermissionsPage = lazy(() => import('../pages/private/Permissions/CreatePermissionsPage'))
const EditPermissionsPage = lazy(() => import('../pages/private/Permissions/EditPermissionsPage'))
const AllRolesPage = lazy(() => import('../pages/private/Role/AllRolesPage'))
const CreateRolePage = lazy(() => import('../pages/private/Role/CreateRolePage'))
const EditRolePage = lazy(() => import('../pages/private/Role/EditRolePage'))
const AllPartnersPage = lazy(() => import('../pages/private/Partner/AllPartnersPage'))
const CreatePartnerPage = lazy(() => import('../pages/private/Partner/CreatePartnerPage'))
const EditPartnerPage = lazy(() => import('../pages/private/Partner/EditPartnerPage'))
const PartnerBankAccountFormPage = lazy(() => import('../pages/private/Partner/PartnerBankAccountFormPage'))
const PartnerCurrentBusinessPage = lazy(() => import('../pages/private/Partner/PartnerCurrentBusinessPage'))
const PartnerDocumentPage = lazy(() => import('../pages/private/Partner/PartnerDocumentPage'))
const AllRetailersPage = lazy(() => import('../pages/private/Retailer/AllRetailersPage'))
const CreateRetailerPage = lazy(() => import('../pages/private/Retailer/CreateRetailerPage'))
const EditRetailerPage = lazy(() => import('../pages/private/Retailer/EditRetailerPage'))
const AllPartnerOrdersPage = lazy(() => import('../pages/private/PartnerOrder/AllPartnerOrdersPage'))
const CreatePartnerOrderPage = lazy(() => import('../pages/private/PartnerOrder/CreatePartnerOrderPage'))
const ViewPartnerOrderPage = lazy(() => import('../pages/private/PartnerOrder/ViewPartnerOrderPage'))
const AllPlansPage = lazy(() => import('../pages/private/Plans/AllPlansPage'))
const CreatePlansPage = lazy(() => import('../pages/private/Plans/CreatePlansPage'))
const EditPlansPage = lazy(() => import('../pages/private/Plans/EditPlansPage'))
const AllCalculationsPage = lazy(() => import('../pages/private/Calculation/AllCalculationsPage'))
const CustomerRechargeHistoryPage = lazy(() => import('../pages/private/History/CustomerRechargeHistoryPage'))
const RetailerTransactionHistoryPage = lazy(() => import('../pages/private/History/RetailerTransactionHistoryPage'))
const PartnerTransactionHistoryPage = lazy(() => import('../pages/private/History/PartnerTransactionHistoryPage'))
const AllEmailTemplatesPage = lazy(() => import('../pages/private/Templates/Email/AllEmailTemplatesPage'))
const EmailTemplateFormPage = lazy(() => import('../pages/private/Templates/Email/EmailTemplateFormPage'))
const AllSmsTemplatesPage = lazy(() => import('../pages/private/Templates/Sms/AllSmsTemplatePage'))
const SmsTemplateFormPage = lazy(() => import('../pages/private/Templates/Sms/SmsTemplateFormPage'))
const AllVariablesTable = lazy(() => import('../pages/private/Variable/AllVariablesTable'))
const EditVariablesPage = lazy(() => import('../pages/private/Variable/EditVariablesPage'))
const AllPrimaryReportsPage = lazy(() => import('../pages/private/Reports/AllPrimaryReportsPage'))
const AllSecondaryReportsPage = lazy(() => import('../pages/private/Reports/AllSecondaryReportsPage'))
const AllTertiaryReportsPage = lazy(() => import('../pages/private/Reports/AllTertiaryReportsPage'))
const AllPlanWiseSubscriptionReportsPage = lazy(() => import('../pages/private/Reports/AllPlanWiseSubscriptionReportsPage'))
const AllIncentiveCommissionTransferReportsPage = lazy(() => import('../pages/private/Reports/AllIncentiveCommissionTransferReportsPage'))
const AllBannersPage = lazy(() => import('../pages/private/Banner/AllBannersPage'))
const CreateBannerPage = lazy(() => import('../pages/private/Banner/CreateBannerPage'))
const EditBannerPage = lazy(() => import('../pages/private/Banner/EditBannerPage'))
const ViewBannerPage = lazy(() => import('../pages/private/Banner/ViewBannerPage'))


// retailer pages
const RetailerDashboardPage = lazy(() => import('../pages/private/Retailer/RetailerDashboardPage/RetailerDashboardPage'))

export const crudType = {
    canCreate: 'canCreate',
    canRead: 'canRead',
    canUpdate: 'canUpdate',
    canDelete: 'canDelete',
}

class GeneratePathForRoutes implements IGeneratePathForRoutes {
    permissionKey: string | string[];
    crudType: {} | null
    url: (values?: string[]) => string
    path: string;
    Component: LoadableComponent<{} & any>
    label: string | undefined
    isShownOnSidebar: boolean | undefined
    key: string | undefined
    props: {} | undefined
    icon: any;

    constructor(
        permissionKey: string | string[],
        crudType: {} | null,
        url: string,
        Component: LoadableComponent<{} & any>,
        label?: string | undefined,
        isShownOnSidebar?: boolean | undefined,
        props?: {} | undefined
    ) {
        this.permissionKey = permissionKey
        this.crudType = crudType
        this.url = function (values = []) {
            const cleanUrl = url.includes('/:') ? url.split('/:')[0] : url
            return values.length === 0 ? cleanUrl : replaceVariableWithValueInURL(url, values)
        };
        this.path = url
        this.Component = Component
        this.label = label
        this.isShownOnSidebar = isShownOnSidebar
        this.key = getUuid();
        this.props = props
    }
}

const publicPages = {
    HomePage: new GeneratePathForRoutes(permissions.forPages.HomePage, null, '/', HomePage),
    // RetailerLoginPage: new GeneratePathForRoutes(permissions.forPages.RetailerLoginPage, null,  '/', RetailerLoginPage),
    LoginPage: new GeneratePathForRoutes(permissions.forPages.LoginPage, null, '/login', LoginPage),
    ForgetPasswordPage: new GeneratePathForRoutes(permissions.forPages.LoginPage, null, '/forget-password', ForgetPasswordPage),
    PageNotFound: new GeneratePathForRoutes(permissions.forPages.PageNotFound, null, '*', PageNotFound),
}

const adminPages = {
    HomePage: new GeneratePathForRoutes(permissions.forPages.HomePage, null, '/', HomePage),
    DashboardPage: new GeneratePathForRoutes([
        permissions.forPages.DashboardPage,
        permissions.forPages.DashboardPagePartner,
    ], null, '/dashboard', DashboardPage),

    // Variable crud
    AllVariablesPage: new GeneratePathForRoutes(permissions.forPages.AllVariablesPage, null, '/variables', AllVariablesTable),
    EditVariablesPage: new GeneratePathForRoutes(permissions.forPages.EditVariablesPage, null, '/variables/:id', EditVariablesPage),

    // Plans crud
    AllPlansPage: new GeneratePathForRoutes(permissions.forPages.AllPlansPage, null, '/plans', AllPlansPage),
    CreatePlansPage: new GeneratePathForRoutes(permissions.forPages.CreatePlansPage, null, '/plans/create', CreatePlansPage),
    EditPlansPage: new GeneratePathForRoutes([permissions.forPages.EditPlansPage, permissions.forPages.AllPlansPage], null, '/plans/edit/:id', EditPlansPage),

    // Email templates crud
    AllEmailTemplatesPage: new GeneratePathForRoutes(permissions.forPages.AllEmailTemplatesPage, null, '/email-templates', AllEmailTemplatesPage),
    CreateEmailTemplatePage: new GeneratePathForRoutes(permissions.forPages.CreateEmailTemplatePage, null, '/email-templates/create', EmailTemplateFormPage),
    EditEmailTemplatePage: new GeneratePathForRoutes(permissions.forPages.EditEmailTemplatePage, null, '/email-templates/:id', EmailTemplateFormPage),


    // Sms template crud
    AllSmsTemplatesPage: new GeneratePathForRoutes(permissions.forPages.AllSmsTemplatesPage, null, '/sms-templates', AllSmsTemplatesPage),
    CreateSmsTemplateFormPage: new GeneratePathForRoutes(permissions.forPages.CreateSmsTemplatePage, null, '/sms-templates/create', SmsTemplateFormPage),
    EditSmsTemplateFormPage: new GeneratePathForRoutes(permissions.forPages.EditSmsTemplatePage, null, '/sms-templates/:id', SmsTemplateFormPage),

    // Account page
    AccountPage: new GeneratePathForRoutes(permissions.forPages.AccountPage, null, '/account', AccountPage),

    // admins crud
    AllAdminsPage: new GeneratePathForRoutes(permissions.forPages.AllAdminsPage, null, '/admins', AllAdminsPage),
    CreateAdminPage: new GeneratePathForRoutes(permissions.forPages.CreateAdminPage, null, '/admins/create', CreateAdminPage),
    EditAdminsPage: new GeneratePathForRoutes([permissions.forPages.EditAdminPage, permissions.forPages.AllAdminsPage], null, '/admins/edit/:id', EditAdminsPage),

    // partners crud
    AllPartnersPage: new GeneratePathForRoutes(permissions.forPages.AllPartnersPage, null, '/partners', AllPartnersPage),
    CreatePartnerPage: new GeneratePathForRoutes(permissions.forPages.CreatePartnerPage, null, '/partners/create', CreatePartnerPage),
    EditPartnerPage: new GeneratePathForRoutes([permissions.forPages.EditPartnerPage, permissions.forPages.AllPartnersPage], null, '/partners/edit/:id', EditPartnerPage),
    PartnerBankAccountFormPage: new GeneratePathForRoutes([permissions.forPages.EditPartnerPage, permissions.forPages.AllPartnersPage], null, '/partners/:partnerId/bank-account/:id?', PartnerBankAccountFormPage),
    PartnerCurrentBusinessPage: new GeneratePathForRoutes([permissions.forPages.EditPartnerPage, permissions.forPages.AllPartnersPage], null, '/partners/:partnerId/current-business/:id?', PartnerCurrentBusinessPage),
    PartnerDocumentPage: new GeneratePathForRoutes([permissions.forPages.EditPartnerPage, permissions.forPages.AllPartnersPage], null, '/partners/:partnerId/document/:id?', PartnerDocumentPage),

    // retailers crud
    AllRetailersPage: new GeneratePathForRoutes([
        permissions.forPages.AllRetailersPage,
        permissions.forPages.AllRetailersByPartner
    ], null, '/retailers', AllRetailersPage),
    CreateRetailerPage: new GeneratePathForRoutes(permissions.forPages.CreateRetailerPage, null, '/retailers/create', CreateRetailerPage),
    EditRetailerPage: new GeneratePathForRoutes([
        permissions.forPages.EditRetailerPage,
        permissions.forPages.AllRetailersPage,
        permissions.forPages.AllRetailersByPartner
    ], null, '/retailers/edit/:id', EditRetailerPage),

    // Banners crud
    AllBannersPage: new GeneratePathForRoutes(permissions.forPages.AllBannersPage, null, '/banners', AllBannersPage),
    CreateBannerPage: new GeneratePathForRoutes(permissions.forPages.CreateBannerPage, null, '/banners/create', CreateBannerPage),
    EditBannerPage: new GeneratePathForRoutes(permissions.forPages.CreateBannerPage, null, '/banners/edit/:id', EditBannerPage),
    ViewBannerPage: new GeneratePathForRoutes(permissions.forPages.CreateBannerPage, null, '/banners/view/:id', EditBannerPage),

    // permissions crud
    AllPermissionsPage: new GeneratePathForRoutes(permissions.forPages.AllPermissionsPage, null, '/permissions', AllPermissionsPage),
    CreatePermissionsPage: new GeneratePathForRoutes(permissions.forPages.CreatePermissionsPage, null, '/permissions/create', env.IS_PRODUCTION_MODE ? PageNotFound : CreatePermissionsPage),
    EditPermissionsPage: new GeneratePathForRoutes(permissions.forPages.EditPermissionsPage, null, '/permissions/edit/:id', env.IS_PRODUCTION_MODE ? PageNotFound : EditPermissionsPage),

    // roles crud
    AllRolesPage: new GeneratePathForRoutes(permissions.forPages.AllRolesPage, null, '/roles', AllRolesPage),
    CreateRolePage: new GeneratePathForRoutes(permissions.forPages.AllRolesPage, null, '/roles/create', CreateRolePage),
    EditRolePage: new GeneratePathForRoutes(permissions.forPages.AllRolesPage, null, '/roles/edit/:id', EditRolePage),

    // partner orders crud
    AllPartnerOrdersPage: new GeneratePathForRoutes([
        permissions.forPages.AllPartnerOrdersPage,
        permissions.forPages.AllPartnerOrdersByPartner
    ], null, '/partner-orders', AllPartnerOrdersPage),
    CreatePartnerOrderPage: new GeneratePathForRoutes(permissions.forPages.CreatePartnerOrderPage, null, '/partner-orders/create', CreatePartnerOrderPage),
    ViewPartnerOrderPage: new GeneratePathForRoutes([
        permissions.forPages.AllPartnerOrdersPage,
        permissions.forPages.AllPartnerOrdersByPartner
    ], null, '/partner-orders/view/:id', ViewPartnerOrderPage),

    // calculations
    AllCalculationsPage: new GeneratePathForRoutes([
        permissions.forPages.AllCalculationsPage,
        permissions.forPages.AllCalculationsPageForPartner,
    ], null, '/calculations', AllCalculationsPage),

    // History
    CustomerRechargeHistoryPage: new GeneratePathForRoutes([
        permissions.forPages.ViewAllCustomer,
        permissions.forPages.ViewCustomerByPartner,
    ], null, '/customer-recharges', CustomerRechargeHistoryPage),
    RetailerTransactionHistoryPage: new GeneratePathForRoutes([
        permissions.forPages.ViewRetailerTransaction,
        permissions.forPages.ViewRetailerTransactionForPartner,
    ], null, '/retailer-transactions', RetailerTransactionHistoryPage),
    PartnerTransactionHistoryPage: new GeneratePathForRoutes([
        permissions.forPages.ViewPartnerTransaction,
        permissions.forPages.ViewPartnerTransactionForPartner,
    ], null, '/partner-transactions', PartnerTransactionHistoryPage),

    // Reports
    AllPrimaryReportsPage: new GeneratePathForRoutes(permissions.forPages.PrimaryReport, null, '/primary-reports', AllPrimaryReportsPage),
    AllSecondaryReportsPage: new GeneratePathForRoutes(permissions.forPages.SecondaryReport, null, '/secondary-reports', AllSecondaryReportsPage),
    AllTertiaryReportsPage: new GeneratePathForRoutes(permissions.forPages.TertiaryReport, null, '/tertiary-reports', AllTertiaryReportsPage),
    AllPlanWiseSubscriptionReportsPage: new GeneratePathForRoutes(permissions.forPages.PlanWiseSubscription, null, '/plan-wise-subscription-reports', AllPlanWiseSubscriptionReportsPage),
    AllIncentiveCommissionTransferReportsPage: new GeneratePathForRoutes(permissions.forPages.AllIncentiveCommissionTransferReportsPage, null, '/incentive-commission-transfer-reports', AllIncentiveCommissionTransferReportsPage),

    PageNotFound: new GeneratePathForRoutes(permissions.forPages.PageNotFound, null, '*', PageNotFound),
}

const retailerPages = {
    RetailerDashboardPage: new GeneratePathForRoutes(permissions.forPages.DashboardPage, null, '/dashboard', RetailerDashboardPage),

    PageNotFound: new GeneratePathForRoutes(permissions.forPages.PageNotFound, null, '*', PageNotFound),
}

const routes = {
    publicPages,
    adminPages,
    retailerPages
}

export default routes;