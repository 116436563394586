import { createTheme, Palette } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

// Override Mui's theme typings to include the new theme property
declare module '@mui/material/styles' {
	// allow configuration using `createTheme`
	interface ThemeOptions {
		overrides?: {},
		typography?: TypographyOptions | ((palette: Palette) => TypographyOptions) | undefined
	}
}

export const sharedThemePalette = {
    primary: {
        main: '#F81004',
		dark: '#F81004',
		light: '#F81004',
		contrastText: '#ffffff'
    },
    secondary: {
        main: '#F81004',
		dark: '#F81004',
		light: '#F81004',
		contrastText: '#ffffff'
    },
	success: {
		main: '#1aaa1f',
		dark: '#1aaa1f',
		light: '#1aaa1f',
		contrastText: '#FFFFFF',
	},
    error: {
        main: '#DB524E',
        dark: '#DB524E',
        light: '#DB524E',
        contrastText: '#F2CACA'
    },
    background: {
        default: '#FFFFFF',
		paper: '#FFFFFF'
    },
}

const typography: TypographyOptions = {
	fontFamily: [
		'IBM Plex Sans',
		'sans-serif',
	].join(','),

	h1: {
		fontFamily: '"Plus Jakarta Sans", sans-serif',
		fontSize: 32,
		fontWeight: 600,
	},
	h2: {
		fontFamily: '"Plus Jakarta Sans", sans-serif',
	},
	h3: {
		fontFamily: '"Plus Jakarta Sans", sans-serif',
	},
	h4: {

		fontFamily: '"Plus Jakarta Sans", sans-serif',
	},
	h5: {
		fontFamily: '"Plus Jakarta Sans", sans-serif',
	},
	h6: {
		fontFamily: '"Plus Jakarta Sans", sans-serif',
	},
	body1: {
		fontFamily: '"IBM Plex Sans", sans-serif',
	},
	body2: {
		fontFamily: '"IBM Plex Sans", sans-serif',
	},
	button: {
		fontFamily: '"IBM Plex Sans", sans-serif',
		textTransform: "capitalize",
	}
}

const overrides = {
	MuiInputLabel: {
		root: {
			fontSize: 12
		}
	},
	MuiInput: {
		root: {
			fontSize: 14
		}
	},
	MuiFormControl: {
		root: {
			marginTop: 14
		}
	},
	'@global': {
		"*": {
			WebkitFontSmoothing: 'auto',
		},
	},
}


// Create a theme instance.
export const lightTheme = createTheme({
    overrides,
    typography,
    palette: {
        ...sharedThemePalette,
		mode: 'light'
    },
});

export const darkTheme = createTheme({
    overrides,
    typography,
    palette: {
        ...sharedThemePalette,
		primary: {
			main: '#F81004',
			dark: '#F81004',
			light: '#F81004',
			contrastText: '#ffffff'
		},		
		secondary: {
			main: '#F81004',
			dark: '#F81004',
			light: '#F81004',
			contrastText: '#ffffff'
		},
        mode: 'dark',
        background: {
            paper: '#000000',
            default: '#000000'
        }
    },
});


export const offlineTheme = createTheme({
	overrides,
    typography,
    palette: {
        ...sharedThemePalette,
		primary: {
			main: '#000000',
			dark: '#000000',
			light: '#000000',
			contrastText: '#ffffff'
		},		
		secondary: {
			main: '#333333',
			dark: '#333333',
			light: '#333333',
			contrastText: '#ffffff'
		},
        mode: 'dark',
        background: {
            paper: '#111111',
            default: '#111111'
        }
    },
})