// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBBCIataSwuyc2-Qd2-3BMRz9Fw793psds",
    authDomain: "hoichoi-pos.firebaseapp.com",
    projectId: "hoichoi-pos",
    storageBucket: "hoichoi-pos.appspot.com",
    messagingSenderId: "196315338857",
    appId: "1:196315338857:web:1a5529c03e3c91e54f2af8",
    measurementId: "G-3XZ3BK5M09"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app