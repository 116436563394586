import FullDarkLogo from '../assets/dark-logo.svg';
import { ReactComponent as DizzinessFilled } from '../assets/dizziness.svg';
import Empty from '../assets/images/empty.svg';
import NotFound from '../assets/images/not_found.svg';
import FullLightLogo from '../assets/light-logo.svg';
import Logo from '../assets/logo.svg';
import FullLogo from '../assets/logo_full.svg';
import LogoWhite from '../assets/logo_white.svg';

export const images = {
    NotFound,
    Empty,
    Logo,
    FullLightLogo,
    FullDarkLogo,
    FullLogo,
    LogoWhite
}

export const icons = {
    DizzinessFilled
}