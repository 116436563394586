import { nanoid } from "nanoid"
import { Dispatch } from "redux"
import { appHistory } from "../../components/router/AppRouter"
import permissions from "../../constants/permissions"
import routes from "../../constants/routes"
import { login } from "../../services/users"
import { ILoginFormData } from "../../types"
import { objectifyArrayOfObjects } from "../../utils/others"
import { authenticateAction, logoutAction, setPartnerDetailsAction, setPermissionsAction, setPermissionsByKeysAction, setUserDetailsAction } from "../actions/authActions"
import { clearPrivateMasterdataAction } from "../actions/masterdataActions"


const tempLogin = (dispatch: Dispatch, allowAdminAccess = false) => {
        const userAuthData = {
            jwt: 'eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIyMDIyMzMzNzU5MjQxTDdMUVlNMEsxMEJpZ0IiLCJyb2xlIjpbeyJhdXRob3JpdHkiOiJjYW5DcmVhdGUifSx7ImF1dGhvcml0eSI6ImNhblVwZGF0ZSJ9LHsiYXV0aG9yaXR5IjoiY2FuRGVsZXRlIn1dLCJleHAiOjE2NDY5NTI2MjcsImlhdCI6MTY0NjkxNjYyN30.qFiUGz4IVfaW0dFDNgvpOBvPHvgWRQwiIYLee8gkRh-Z_wDzTztnFm66F6ulaQSobMQW1goHGd1L8aep8o9u8mbeWxlDBv1f3c1t2PTtlN8-9UCY0GXfN0NBw0341lYXSfi6DINGRCZAmfwXxEBJS1rgbqwZfl7p2H8PPQz0DKwC4WrNy5J3uOEIX4ntznJ_z9jBfc7yOA7rx8mymuvvUFLK-ls-8FNYkU0z0UaZN69PQi-7oKTxFPh2bCWRnEGZK93BiltaFhLIqGVE2x51sGCW9Ef_Xn8n2GWRFTo90kP5Ro1s8YFoGnf10yfPqhPhV2I38Vty7-wHJ6INHBaqXg',
            refreshToken: 'hj345ghjasdasdwer2123sdf',
            roleId: '1',
            permissions: [
                ...Object.values(permissions.forPages),
                ...Object.values(permissions.forSidebar),
            ],
            permissionsByKeys: {},
            userId: 'US001',
            tokenExpiryTimestamp: (new Date()).getTime(),         // logout after current time + time set in backend
            user: {
                createdAt: '',
                createdBy: '',
                name: '',
                email: '',
                mobile: '',
                mobileVerified: 1,
                emailVerified: 1,
                id: '',
                isActive: 1,
                isDeleted: 0,
                lastModifiedBy: '',
                lastModifiedDate: '',
                roleEntity: {
                    adminPortal: allowAdminAccess,
                    createdAt: '',
                    createdBy: '',
                    description: '',
                    id: '',
                    isActive: 0,
                    isDeleted: 0,
                    lastModifiedBy: '',
                    lastModifiedDate: '',
                    name: '',
                    permissionEntities: [],
                    group: 0
                }
            }
        }
        dispatch(authenticateAction(userAuthData))
        appHistory.push(allowAdminAccess ? routes.adminPages.DashboardPage.url() : routes.retailerPages.RetailerDashboardPage.url())
}

export const loginDispatcher = (formData: ILoginFormData, loginWithMobile = false, onSuccess = () => {}, onFailure = (otpResp?: Otp) => {}) => {
    return async (dispatch: Dispatch) => {

        // tempLogin(dispatch)
        
        const { error, data } = await login({ 
            ...formData,
            device: 'Browser',
            deviceId: nanoid()
        }, loginWithMobile)

        if (error) {
            console.log(error.body);
            onFailure()
            return;
        }

        
        if (data) {
            if (data.payload.otpResDto) {
                onFailure(data.payload.otpResDto)
                return;
            }
            onSuccess();            

            const userAuthData = {
                jwt: data.payload.jwt,
                refreshToken: data.payload.refreshToken,
                roleId: data.payload.userEntity.roleEntity.id,
                permissions: [
                    // ...Object.values(permissions.forPages),
                    // ...Object.values(permissions.forSidebar),
                    ...data.payload.userEntity.roleEntity.permissionEntities.map(permission => permission.key),
                ],
                permissionsByKeys: objectifyArrayOfObjects<Permission>(data.payload.userEntity.roleEntity.permissionEntities, 'key'),
                userId: data.payload.userEntity.id,
                tokenExpiryTimestamp: (new Date(data.payload.expiration)).getTime(),         // logout after current time + time set in backend
                user: data.payload.userEntity
            }
            dispatch(authenticateAction(userAuthData))
            appHistory.push(data.payload.userEntity.roleEntity.adminPortal ? routes.adminPages.DashboardPage.url() : routes.retailerPages.RetailerDashboardPage.url())
        }
    }
}

export const logoutDispatcher = (nextPageUrl: string = routes.publicPages.LoginPage.url()) => {
    return async (dispatch: Dispatch) => {
        // logout the user
        dispatch(logoutAction())

        // clear all user related private masterdata
        dispatch(clearPrivateMasterdataAction())

        // redirect to homepage
        appHistory.push(nextPageUrl)
    }
}

export const setUserDetailsDispatcher = (user: User) => {
    return async (dispatch: Dispatch) => {
        dispatch(setUserDetailsAction(user))
    }
}
export const setPermissionsDispatcher = (permission: string[]) => {
    return async (dispatch: Dispatch) => {
        dispatch(setPermissionsAction(permission))
    }
}
export const setPermissionsByKeysDispatcher = (permissionByKeys: {}) => {
    return async (dispatch: Dispatch) => {
        dispatch(setPermissionsByKeysAction(permissionByKeys))
    }
}

export const setPartnerDetailsDispatcher = (partner: Partner) => {
    return async (dispatch: Dispatch) => {
        dispatch(setPartnerDetailsAction(partner))
    }
}