import authConstants from "../constants/auth";

interface IAuthenticateActionData {
    jwt: string | null;
    refreshToken: string | null;
    roleId: string | null;
    userId: string | null;
    permissions: string[],
    permissionsByKeys: {},
    tokenExpiryTimestamp: number | null
    user: User
}

export const authenticateAction = (data: IAuthenticateActionData) => {
    return {
        type: authConstants.USER_AUTHENTICATE,
        payload: {
            token: data.jwt,
            refreshToken: data.refreshToken,
            roleId: data.roleId,
            permissions: data.permissions,
            permissionsByKeys: data.permissionsByKeys,
            userId: data.userId,
            tokenExpiryTimestamp: data.tokenExpiryTimestamp,
            user: data.user
        },
    }
}

export const logoutAction = () => {
    return {
        type: authConstants.USER_LOGOUT
    }
}

export const setUserDetailsAction = (user: User) => {
    return {
        type: authConstants.SET_USER_DETAILS,
        payload: user
    }
}

export const setPermissionsAction = (permissions: string[]) => {
    return {
        type: authConstants.SET_PERMISSIONS,
        payload: permissions
    }
}

export const setPermissionsByKeysAction = (permissionsByKeys: {}) => {
    return {
        type: authConstants.SET_PERMISSIONS_BY_KEYS,
        payload: permissionsByKeys
    }
}

export const setPartnerDetailsAction = (partnerEntity: Partner) => {
    return {
        type: authConstants.SET_PARTNER_DETAILS,
        payload: partnerEntity
    }
}

