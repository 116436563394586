import { getMessaging } from "firebase/messaging";
import app from ".";

const startFirebaseMessaging = () => {
    const messaging = getMessaging(app);
}


const messaging = async () => {
    try {
        if (Notification.permission === 'default') {
            const response = await Notification.requestPermission()

            if (response === 'granted') {
                startFirebaseMessaging()
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export const requestForToken = () => {
    const messaging = getMessaging(app);

    // return getToken(messaging, { vapidKey: 'REPLACE_WITH_YOUR_VAPID_KEY' })
    //   .then((currentToken) => {
    //     if (currentToken) {
    //       console.log('current token for client: ', currentToken);
    //       // Perform any other neccessary action with the token
    //     } else {
    //       // Show permission request UI
    //       console.log('No registration token available. Request permission to generate one.');
    //     }
    //   })
    //   .catch((err) => {
    //     console.log('An error occurred while retrieving token. ', err);
    //   });
};

export default messaging