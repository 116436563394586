import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import env from '../env';
import allReducers from './reducers';


const persistConfig = {
    key: env.APP_REDUX_KEY,
    storage: storage,
    writeFailHandler: function (err: any) {
        console.log("ERR in writeFailHandler::::", err);
    },

    //These stores will persist
    whitelist: [
        'auth',
        'theme',
        'publicMasterdata',
        'privateMasterdata',
        'formReducer'
    ],
}

const composeEnhancers = (typeof window !== 'undefined' && env.WITH_REDUX_DEVTOOLS === 'true') ? composeWithDevTools({
    trace: true
}) : compose;

const persistedReducer = persistReducer(persistConfig, allReducers);

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

export const persistor = persistStore(store);
