import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Drawer, List, ListItem, ListItemButton, useTheme } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import { ClassNameMap } from "@mui/styles";
import clsx from 'clsx';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { images } from "../../../constants/assets";
import useCommonStyles from '../../../styles/useCommonStyles';
import useSidebarStyles from '../../../styles/useSidebarStyles';
import Text from '../Text';
import { default as navMenuListConstant, IMenuGenerator } from './navMenuList';
import SidebarMenuSearch from "./SidebarMenuSearch";

interface Props {
    openSidebar: boolean
    setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>
    classes: ClassNameMap<string>
}

const Sidebar: React.FC<Props> = ({
    openSidebar,
    setOpenSidebar,
    classes
}) => {
    const sidebarStyles = useSidebarStyles();
    const commonStyles = useCommonStyles()
    const theme = useTheme();
    const handleDrawerClose = () => {
        setOpenSidebar(false);
    };
    const [selectedMenuPath, setSelectedMenuPath] = useState('');
    const history = useHistory();
    const pathName = history.location.pathname;
    const userPermissions = useSelector((state: RootState) => state.auth.permissions);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const [navMenuList, setNavMenuList] = useState([...navMenuListConstant])

    const handleFilterNavMenuList = (updatedNavMenuList: Array<IMenuGenerator> = []) => setNavMenuList(updatedNavMenuList)

    const getIsLinkSelected = (pathName: string) => {
        return selectedMenuPath.includes(pathName)
    }

    const RenderNavListItem = (item: IMenuGenerator) => userPermissions.some(permission => item.permissionKey.includes(permission)) ? (
        <li key={item.key}>
            <ListItemButton
                selected={getIsLinkSelected(item.pathname as string)}
                component={getIsLinkSelected(item.pathname as string) ? 'div' : Link}
                to={getIsLinkSelected(item.pathname as string) ? undefined : item.pathname as string}
            >
                {item.icon && <item.icon />}
                <Text ml={1} className={sidebarStyles.listItemText}>{item.label}</Text>
            </ListItemButton>
        </li>
    ) : null

    const RenderNavMenu = (item: IMenuGenerator, index: number) => {
        if (item.children.length === 0) {
            return RenderNavListItem(item)
        }
        else {
            return userPermissions.some(permission => item.permissionKey.includes(permission)) ? (
                <li key={item.key}>
                    <Accordion defaultExpanded elevation={0} className={sidebarStyles.accordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            {item.icon && <item.icon />}
                            <Text className={sidebarStyles.listItemText}>{item.label}</Text>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List className={clsx(commonStyles.width100, sidebarStyles.nestedList)}>
                                {item.children.map(RenderNavMenu)}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                    {index !== navMenuList.length - 1 ? <Divider /> : null}
                </li>
            ) : null
        }
    }

    React.useEffect(() => {
        setSelectedMenuPath(pathName)
    }, [pathName])

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: openSidebar,
                [classes.drawerClose]: !openSidebar,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: openSidebar,
                    [classes.drawerClose]: !openSidebar,
                }),
            }}
        >
            <div className={classes.toolbar}>
                <img className={sidebarStyles.logo} src={isDarkMode ? images.FullDarkLogo : images.FullLightLogo} alt='full logo' />
                <IconButton aria-label='toggle sidebar' title='toggle sidebar' onClick={handleDrawerClose}>
                    {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </div>
            <Divider />
            <SidebarMenuSearch handleFilterNavMenuList={handleFilterNavMenuList} />
            <List className={sidebarStyles.root}>
                {
                    navMenuList.length > 0 ? navMenuList.map(RenderNavMenu) : (
                        <ListItem key={989898989898989}>
                            <Text className={sidebarStyles.listItemText}>No links found!</Text>
                        </ListItem>
                    )
                }
            </List>
        </Drawer>
    )
}

export default Sidebar
