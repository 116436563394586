import { AnyAction } from "redux"
import formConstants from "../constants/form"


interface IReduxForm {
    publicForms: IPublicForms
}

const initialState: IReduxForm = {
    publicForms: {
        PricingForm: {}
    }
}

const formReducer = (state = initialState, action: AnyAction): IReduxForm => { 
    switch (action.type) {
        case formConstants.SET_PUBLIC_FORMDATA:
            return {
                ...state,
                publicForms: {
                    ...state.publicForms,
                    ...action.payload
                }
            }
    
        default:
            return state
    }
}

export default formReducer