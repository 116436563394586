import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import routes from '../../constants/routes'
import PageNotFound from '../../pages/PageNotFound'
import { RenderRoute } from './AppRouter'


const PublicRoutes: React.FC = () => {
    const location = useLocation()
    return (
        <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
                {
                    Object.values(routes.publicPages).map(RenderRoute)
                }
                <Route path='*' exact={true} component={PageNotFound} />
            </Switch>
        </AnimatePresence>
    )
}

export default PublicRoutes
