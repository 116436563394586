import { stringifyUrl } from "query-string";
import Axios from "../interceptors/axiosInstance";
import Request from "../interceptors/Request";

const RoleService = new Request(Axios)

export const getAllRolesByStatus = (data: Status) => {
    return RoleService.post<null, Response<Role[]>>(stringifyUrl({url: '/role/getByStatus', query: {...data}}), null)
}

export const createRole = (data: CreateRoleReqBody) => {
    return RoleService.post<typeof data, Response<Role>>('/role/create', data)
}

export const updateRole = (data: CreateRoleReqBody, isActive: number, roleId: string) => {
    return RoleService.post<typeof data, Response<Role>>(stringifyUrl({url: '/role/update', query: {isActive, roleId}}), data)
}

export const getRoleById = (data: Status & {roleId: string}) => {
    return RoleService.post<{}, Response<Role>>(stringifyUrl({url: '/role/getById', query: {...data}}), {})
}

export const updateRoleState = (roleId: string, isActive: number) => {
    return RoleService.post<null, Response<Role>>(stringifyUrl({url: '/role/updateState', query: {isActive, roleId}}), null)
}

export const getAssignableRoles = () => {
    return RoleService.post<null, Response<Role[]>>(stringifyUrl({url: '/role/getAssignable'}), null)
}