import { combineReducers } from "redux";
import authReducer from "./authReducer";
import formReducer from "./formReducer";
import privateMasterdataReducer from "./privateMasterdataReducer";
import publicMasterdataReducer from "./publicMasterdataReducer";
import themeReducer from "./themeReducer";

const reducers = combineReducers({
    auth: authReducer,
    theme: themeReducer,
    publicMasterdata: publicMasterdataReducer,
    privateMasterdata: privateMasterdataReducer,
    formReducer: formReducer,
})

export default reducers;