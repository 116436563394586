import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { fadeColor } from "../utils/styles";

const useSidebarStyles = makeStyles<Theme>(theme => ({
    root: {
        overflowY: 'scroll',
        '& .MuiSvgIcon-root': {
            width: 18,
            height: 18,
            color: theme.palette.text.primary,
        },
        "& .MuiListItemButton-root": {
            borderRadius: 4,
            marginBottom: 6
        },
        "& .MuiListItemButton-root.Mui-selected": {
            background: `linear-gradient(90deg,${fadeColor(theme.palette.primary.main, 0.1)}, ${fadeColor(theme.palette.secondary.main, 0.1)})`,
            '& .MuiTypography-body1, & .MuiSvgIcon-root': {
                color: theme.palette.getContrastText(theme.palette.primary.main)
            },
        },
    },
    listItemText: {
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    logo: {
        width: 120,
        margin: 'auto'
    },
    topGutter: {
        paddingTop: theme.spacing(2),
    },
    accordion: {
        '&:before': {
            display: 'none'
        },

        '& .MuiAccordionSummary-root': {
            paddingLeft: 8
        },

        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 48,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
        },
        '& .MuiAccordionSummary-content': {
            alignItems: 'center',

            '& .MuiTypography-root': {
                marginRight: theme.spacing(1)
            }
        },
        '& .MuiAccordionDetails-root': {
            padding: 0
        },
        '& .MuiListItem-root': {
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
        },
    },
    nestedList: {
        padding: theme.spacing(1),
        paddingTop: 0,
    },

}))

export default useSidebarStyles