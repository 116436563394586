import { Theme, Typography, TypographyProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles<Theme, Props>((theme) => ({
    root: {
        fontWeight: ({ fontWeight }) => fontWeight,
        fontSize: ({ fontSize }) => fontSize,
        margin: ({ margin }) => margin,
        textTransform: ({ textTransform }) => textTransform
    }
}))

interface Props extends TypographyProps {
    color?: string
    fontWeight?: number
    fontSize?: number | string
    margin?: number | string
    textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase' | 'full-width' | 'full-size-kana'
    [key: string]: any;
}

const Text: React.FC<Props> = ({
    color = "textPrimary",
    fontWeight = 400,
    fontSize,
    className,
    children,
    margin,
    textTransform,
    ...props
}) => {

    const classes = useStyles({
        fontWeight,
        fontSize,
        textTransform,
        margin
    });

    return (
        <Typography color={color} className={clsx(classes.root, className)} {...props}>
            {children}
        </Typography>
    )
}

Text.propTypes = {

}

export default Text
