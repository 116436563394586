import { AnyAction } from "redux";
import authConstants from "../constants/auth";

interface IAuthState {
    token: string | null;
    refreshToken: string | null;
    roleId: string | null;
    userId: string | null;
    permissions: string[];
    permissionsByKeys: {};
    tokenExpiryTimestamp: number | null;
    user: User
    partnerEntity: Partner | null
}

const initialState: IAuthState = {
    token: null,
    refreshToken: null,
    roleId: null,
    userId: null,
    permissions: [],
    permissionsByKeys: {},
    tokenExpiryTimestamp: null,
    user: {
        createdAt: '',
        createdBy: '',
        name: '',
        email: '',
        emailVerified: 0,
        mobile: '',
        mobileVerified: 0,
        id: '',
        isActive: 0,
        isDeleted: 0,
        lastModifiedBy: '',
        lastModifiedDate: '',
        roleEntity: {
            group: 0,
            adminPortal: false,
            createdAt: '',
            createdBy: '',
            description: '',
            id: '',
            isActive: 0,
            isDeleted: 0,
            lastModifiedBy: '',
            lastModifiedDate: '',
            name: '',
            permissionEntities: []
        }
    },
    partnerEntity: null
}

const authReducer = (state = initialState, action: AnyAction): IAuthState => {
    switch (action.type) {
        case authConstants.USER_AUTHENTICATE:
            return {
                ...state,
                ...action.payload
            }

        case authConstants.SET_USER_DETAILS:
            return {
                ...state,
                user: action.payload
            }

        case authConstants.SET_PARTNER_DETAILS:
            return {
                ...state,
                partnerEntity: action.payload
            }
    
        case authConstants.SET_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload
            }
            
        case authConstants.SET_PERMISSIONS_BY_KEYS:
            return {
                ...state,
                permissionsByKeys: action.payload
            }

        case authConstants.USER_LOGOUT:
            return initialState
    
        default:
            return state;
    }
}

export default authReducer;