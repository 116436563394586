import { Dispatch } from "redux";
import { getActivePermissions } from "../../services/permission";
import { getActiveByStatus } from "../../services/plan";
import { getAssignableRoles } from "../../services/role";
import { IPrivateMasterdataState } from "../../types";
import { privateMasterdataAction } from "../actions/masterdataActions";

const privateMasterdataDispatcher = () => {
    return async (dispatch: Dispatch) => {
        try {
            // setting up private data
            const finalMasterdataForRedux: IPrivateMasterdataState = {
                allRoles: (await getAssignableRoles()).data?.payload || [],
                allPermissions: (await getActivePermissions()).data?.payload || [],
                allPlans: (await getActiveByStatus()).data?.payload || [],
            }

            dispatch(privateMasterdataAction(finalMasterdataForRedux));
        } catch (error) {
            console.log('error occured while fetching private masterdata', error);
        }
    }
}


export default privateMasterdataDispatcher