import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import privateMasterdataDispatcher from '../../../redux/dispatchers/privateMasterdataDispatcher'

const MasterdataWrapper: React.FC = ({
    children
}) => {
    const dispatch = useDispatch()
    const allowedForAdminPortal = useSelector((state: RootState) => state.auth.user.roleEntity.adminPortal)

    // React.useEffect(() => {
    //     dispatch(publicMasterdataDispatcher())
    // }, [])

    React.useEffect(() => {
        if (allowedForAdminPortal) {
            dispatch(privateMasterdataDispatcher())
        }
    }, [allowedForAdminPortal])

    return (
        <>
            {children}
        </>
    )
}

export default MasterdataWrapper
