import { Action, IPublicMasterdataState } from "../../types";
import { masterdataConstants } from "../constants/masterdata";

const initialState: IPublicMasterdataState = {
    // allEvents: []
}

const publicMasterdataReducer = (state = initialState, action: Action): IPublicMasterdataState => {
    switch (action.type) {
        case masterdataConstants.FETCH_PUBLIC_MASTER_DATA:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}

export default publicMasterdataReducer;