import { AnyAction } from "redux";
import { DISABLE_DARK_MODE, ENABLE_DARK_MODE } from "../constants/theme";

interface IThemeReducer {
    isDarkMode: boolean
}

const initialState = {
    // isDarkMode: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    isDarkMode: false
}

const themeReducer = (state = initialState, action: AnyAction): IThemeReducer => {
    switch (action.type) {
        case ENABLE_DARK_MODE:
            return {
                ...state,
                isDarkMode: action.payload  // true
            }

        case DISABLE_DARK_MODE:
            return {
                ...state,
                isDarkMode: action.payload  // false
            }

        default:
            return state;
    }
}

export default themeReducer