import axios from "axios";
import env from "../env";

const Axios = axios.create({
    baseURL: env.ADMIN_BACKEND_BASE_URL
});

export const TransactionMicroService = axios.create({
    baseURL: env.TRANSACTION_BASE_URL
});

export const MasterMicroService = axios.create({
    baseURL: env.MASTER_BASE_URL
});

export const PincodeService = axios.create({
    baseURL: env.PINCODE_API_BASE_URL
});


export const cancelToken = axios.CancelToken

export default Axios;