import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { OnChangeEvent } from '../../../types';
import { darkenColor } from '../../../utils/styles';
import TextBox from '../Inputs/TextBox';
import navMenuList, { IMenuGenerator } from './navMenuList';

const useStyles = makeStyles<Theme>(theme => ({
    textBox: {
        margin: '0 !important',
        padding: '0.5rem',
        border: `2px solid ${theme.palette.divider}`,
        backgroundColor: darkenColor(theme.palette.background.paper, 0.025),

        '&:before, &:hover:before, &:after': {
            borderBottom: 'none !important',
            background: 'transparent'
        }
    }
}))

interface Props {
    handleFilterNavMenuList: (list: Array<IMenuGenerator>) => void
}

const SidebarMenuSearch: React.FC<Props> = ({ handleFilterNavMenuList }) => {
    const classes = useStyles()
    const { handleSubmit, control, reset } = useForm()

    const handleFormSubmit = (data: any) => {

    }

    /**
     * Recurssively searches for link and returns the results as an array
     */
    const searchNavMenuList: (searchQuery: string | undefined, node: IMenuGenerator) => unknown = (searchQuery = '', node: IMenuGenerator) => {
        if (node.children.length > 0) {
            return node.children.map((eachNode: IMenuGenerator) => searchNavMenuList(searchQuery, eachNode))
        }
        else if (node.label.toLowerCase().includes(searchQuery)) {
            return [node]
        }
        return []
    }

    const getNavMenuList = (searchQuery = '') => {
        return navMenuList.map(eachNode => searchNavMenuList(searchQuery, eachNode)).flat(999)
    }

    const clearSearch = () => {
        reset({ searchNavMenu: '' })
        handleFilterNavMenuList([...navMenuList])
    }

    const handleOnChange = (onChange: any) => (e: OnChangeEvent) => {
        const value = e.target.value
        onChange(value)

        if (value.length === 0) {
            clearSearch()
        } else {
            // filter nav list
            const filterdList = getNavMenuList(value)
            handleFilterNavMenuList(filterdList as Array<IMenuGenerator>)
        }

    }

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Controller
                name='searchNavMenu'
                control={control}
                render={({ field: { onChange, value } }) => (
                    <TextBox
                        name='searchNavMenu'
                        inputClassName={classes.textBox}
                        onChange={handleOnChange(onChange)}
                        endAdornment={
                            !!value ? <IconButton aria-label='clear sidebar search query' onClick={clearSearch}><ClearIcon /></IconButton> : null
                        }
                        startAdornment={<IconButton title='search for navigation links in sidebar' aria-label='search for navigation links in sidebar' disabled><SearchIcon /></IconButton>}
                        value={value}
                        inputProps={{
                            autoComplete: 'off',
                            placeholder: 'Quick Search...'
                        }}
                    />
                )}
                defaultValue=''
            />
        </form>
    )
}

export default SidebarMenuSearch